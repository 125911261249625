<template>
  <div>

    <div class="form-group row m-b-md" v-if="items.length != 0">
      <div class="col-lg-6">
        <div class="input-group">
          <span class="input-group-prepend">
            <b-button class="btn btn-sm btn-primary" :disabled="table.filter == null || table.filter.length == 0" @click="clearFilter"> {{
                $t('common.actions.clear')
              }}</b-button>
          </span>
          <b-form-input type="text"
                v-model="table.filter"
                debounce="300"
                max="50"
                :placeholder="$t('enterForSearch')"
                class="form-control form-control-sm"/>
        </div>
      </div>
      <div class="col-lg-3 m-t-sm text-right">
        <row-count :size="table.size" :page="table.page" :total="items.length"></row-count>
      </div>
      <div class="col-lg-3 d-flex justify-content-end align-items-start" >
        <button class="btn btn-sm btn-primary align-self-start mt-2 mr-2" @click="exportDevices"><i class="fa-solid fa-download" ></i></button>
        <b-pagination v-if="items.length" size="sm" class="m-t-xs" align="right"
                      :total-rows="items.length"
                      v-model="table.page"
                      :per-page="table.size"></b-pagination>
      </div>    
    </div>

    <div class="row">
      <div class="col-lg-12">
        <b-table responsive _hover _striped
                 v-if="items.length != 0"
                 _class="text-nowrap"
                 thead-class="text-nowrap"
                 :filter="table.filter"
                 :fields="table.fields"
                 :current-page="table.page"
                 :per-page="table.size"
                 :items="items"
                 :sort-by.sync="table.sortBy"
                 :sort-desc.sync="table.sortDesc">
          <template #cell(externalId)="data">
            <b-link :to="{ path: routePrefix + '/devices/' + data.item.id }">{{ data.value }}</b-link>
          </template>
          <template #cell(signalQuality)="data">
            <span v-if="data.value !== null" class="signal-icon-wrapper">
              <template v-if="data.value === '-1'">
                <i class="fas fa-plug-circle-xmark disconnected-icon"></i>
              </template>
              <template v-else>
                <div v-for="n in 3" :key="n" :class="getSignalClass(n, data.value)"></div>
              </template>
            </span>
          </template>
          <template #cell()="data">{{ data.value }}</template>
        </b-table>
        <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>

      </div>
    </div>
    <device-summary v-if="deviceSummary" :summary="deviceSummary" :device-type="deviceType" />

  </div>
</template>

<script>
import {mapState} from "vuex"
import RowCount from '@/components/common/RowCount';
import moment from 'moment-timezone'
import axios from 'axios';
import DeviceSummary from './DeviceSummary';

export default {
  components: {
    RowCount,
    DeviceSummary
  },
  props: {
    items: {
      type: Array
    },
    deviceType: String,
    routePrefix: {
      default: ''
    }
  },
  data() {
    return {
      error: null,
      info: null,
      table: {
        filter: '',
        sortBy: '',
        sortDesc: false,
        page: 1,
        size: 10,
        total: 0,
        paginationOptions: [
          {'value': 5},
          {'value': 25},
          {'value': 50},
          {'value': 100}
        ],
        fields: [
          {
            key: 'externalId',
            label: this.$t('systemAdmin.device.attributes.externalId'),
            sortable: true,
          },
          {
              key: 'deviceModelType',
              label: this.$t('systemAdmin.device.attributes.deviceModel.type'),
              sortable: true,
              sortByFormatted: true,
              filterByFormatted: true,
              formatter: (value, key, item) => {
                  return this.$t('enums.DeviceModelType.' + item.deviceModel.type)
              }
          },
          {
            key: 'alias',
            label: this.$t('systemAdmin.device.attributes.alias'),
            sortable: true,
          },
          {
            key: 'deviceModelManufacturer',
            label: this.$t('systemAdmin.device.attributes.deviceModel.manufacturer'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.deviceModel.manufacturer
            }
          },
          {
            key: 'deviceModelName',
            label: this.$t('systemAdmin.device.attributes.deviceModel.name'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.deviceModel.name
            }
          },
          {
            key: 'organisation',
            label: this.$t('systemAdmin.device.attributes.organisation'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.organisation ? item.organisation.name : ''
            }
          },
          {
            key: 'building',
            label: this.$t('systemAdmin.device.attributes.building'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.building ? item.building.name : ''
            }
          },
          {
              key: 'map',
              label: this.$t('systemAdmin.device.attributes.map'),
              sortable: true,
              sortByFormatted: true,
              filterByFormatted: true,
              formatter: (value, key, item) => {
                  return item.map ? item.map.name : ''
              }
          },
          {
            key: 'location',
            label: this.$t('systemAdmin.device.attributes.location'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.location ? `${item.location.name}${item.location.nameAlternate ? ` (${item.location.nameAlternate})` : ''}` : ''
            }
          },
          {
            key: 'lastSeen',
            label: this.$t('systemAdmin.device.attributes.lastSeen'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.lastSeenAt
                ? (item.building && item.building.timeZone
                  ? moment(item.lastSeenAt).tz(item.building.timeZone).fromNow()
                  : moment(item.lastSeenAt).fromNow())
                : this.$t('systemAdmin.device.seenStatus.never');
            },
            sort: (a, b) => this.sortLastSeen(a, b)
          },
          {
            key: 'battery',
            label: this.$t('systemAdmin.device.attributes.parameters.battery'),
            sortable: true,
            sortDirection: 'asc',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.parameters?.battery ? item.parameters.battery + "%" : "n/a";
            },
            sort: (a, b, key) => this.sortBatteryLevel(a, b, key)
          },
          {
            key: 'signalQuality',
            label: this.$t('systemAdmin.device.attributes.parameters.signalQuality'),
            sortable: true,
            sortDirection: 'desc',
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item?.parameters?.signalQuality ? item.parameters.signalQuality : "n/a";
            },
            sort: (a, b, key) => this.sortSignalQuality(a, b, key)
          }
        ]
      },
      deviceSummary: null,
    }
  },
  computed: {
    prefix() {
      return this.$route.path.startsWith('/admin') ? '/admin' : '';
    },
    ...mapState({
      contextOrg: state => state.contextOrg,
      contextBuilding: state => state.contextBuilding,
    })
  },
  mounted() {
    this.fetchDeviceSummary();
  },
  methods: {
    async fetchDeviceSummary() {
      try {
        const endpoint = this.contextBuilding?.id 
          ? `/admin/buildings/${this.contextBuilding.id}/devices/summary`
          : `/admin/organisations/${this.contextOrg?.id}/devices/summary`;
        
        const response = await axios.get(endpoint, {
          params: {
            deviceType: this.deviceType
          }
        });
        
        this.deviceSummary = response.data;
      } catch (error) {
        console.error('Error fetching device summary:', error);
        this.$bvToast.toast(this.$t('common.errors.summaryLoadError.description'), {
          title: this.$t('common.errors.summaryLoadError.title'),
          variant: 'danger',
          solid: true
        });
      }
    },
    clearFilter() {
      this.table.filter = null
    },
    sortBatteryLevel(a, b, key) {
      const getValue = (item) => {
        const value = item.parameters?.battery ? item.parameters[key] : "n/a";
        return value === "n/a" ? -Infinity : parseFloat(value);
      };

      const valueA = getValue(a);
      const valueB = getValue(b);

      return valueA - valueB;
    },
    sortSignalQuality(a, b, key) {
      const getValue = (item) => {
        const value = item.parameters?.signalQuality ? item.parameters.signalQuality : "n/a";
        return value === "n/a" ? -Infinity : parseInt(value);
      };
      const valueA = getValue(a);
      const valueB = getValue(b);

      return valueA - valueB;
    },
    sortLastSeen(a, b) { //TO-DO: Fix this somehow
      const dateA = a.lastSeenAt ? moment(a.lastSeenAt).valueOf() : 0;
      const dateB = b.lastSeenAt ? moment(a.lastSeenAt).valueOf() : 0;

      return dateA - dateB;
    },
    getSignalClass(n, quality) {
      if (quality === 'n/a') {
        return 'signal-bar signal-empty';
      }
      if (n <= quality) {
        return `signal-bar signal-${quality}`;
      } else {
        return 'signal-bar signal-empty';
      }
    },
    async exportDevices() {
      try {
        const endpoint = this.contextBuilding?.id 
          ? `/admin/buildings/${this.contextBuilding.id}/devices/export`
          : `/admin/organisations/${this.contextOrg?.id}/devices/export`;

        const response = await axios.get(endpoint, {
          params: {type: this.deviceType},
          responseType: 'blob'
        });

        const filename = this.contextBuilding?.id ? `building-${this.contextBuilding.id}-devices.csv` : `org-${this.contextOrg.id}-devices.csv`;

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } 
      catch (error) {
        console.error('Error downloading devices:', error);
        this.$bvToast.toast(this.$t('common.errors.downloadError.description'), {
          title: this.$t('common.errors.downloadError.title'),
          variant: 'danger',
          solid: true
        });
      }
    }
  }
}
</script>

<style scoped>

.signal-icon-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 1.3rem;
  align-items: flex-end;
  justify-content: center;
}

.signal-1 {
  background-color: #A31621;
}

.signal-2 {
  background-color: #FFC000;
}

.signal-3 {
  background-color: #2C6E49;
}

.signal-empty {
  background-color: #E0E0E0;
}

.signal-bar {
  height: 100%;
  border-radius: 3px;
  width: 0.3rem;
  margin: 0.1rem;
}

.signal-bar:nth-of-type(1){
  height: 40%;
}
.signal-bar:nth-of-type(2){
  height: 66%;
}

.signal-bar.signal-empty {
  background-color: lightgray;
}

.disconnected-icon {
  color: red;
  font-size: 1.2rem;
}
</style>