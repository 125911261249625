<template>
    <div>
        <validation-provider name="orgAdmin.form.attributes.reminderTime" :rules="{ required: true }"
            v-slot="validationContext">
            <b-form-group :label="$t('orgAdmin.form.attributes.reminderTime')" label-cols-sm="2">
                <div v-if="type === 'view' && !schedules.length" class="text-muted">
                    {{ form.reminderTime || 'No schedule set' }}
                </div>

                <div v-else class="reminder-schedule">
                    <b-form-group v-if="type !== 'view'" :label="$t('orgAdmin.form.reminderSchedule.selectDays')" class="mb-3">
                        <b-form-checkbox-group v-model="selectedDays" :options="daysOfWeek"
                            :disabled="!form.sendReminder" buttons button-variant="outline-primary" size="sm"
                            name="days-buttons"></b-form-checkbox-group>
                    </b-form-group>

                    <b-form-group v-if="type !== 'view'" :label="$t('orgAdmin.form.reminderSchedule.selectTime')" class="mb-3">
                        <div class="d-flex align-items-center gap-2">
                            <select v-model="selectedTime" 
                                   class="form-control form-control-sm w-auto" 
                                   :disabled="!form.sendReminder">
                                <option value="">{{ $t('orgAdmin.form.reminderSchedule.noTimeSelected') }}</option>
                                <option v-for="hour in 24" :key="hour-1" :value="formatHourValue(hour-1)">
                                    {{ formatHourDisplay(hour-1) }}
                                </option>
                            </select>

                            <b-button size="sm" variant="outline-secondary"
                                :disabled="!selectedTime || !selectedDays.length || !form.sendReminder"
                                @click="addSchedule">
                                {{ $t('orgAdmin.form.reminderSchedule.addSchedule') }}
                            </b-button>
                        </div>
                    </b-form-group>

                    <b-list-group v-if="schedules.length" class="mb-3">
                        <b-list-group-item v-for="(daySchedule, index) in formatSchedules()" :key="index"
                            class="d-flex align-items-center">
                            <span class="day-label">{{ getDayName(daySchedule.day) }}:</span>
                            <div class="time-pills">
                                <span v-for="time in daySchedule.times" :key="time" 
                                    class="time-pill">
                                    {{ formatTime(time) }}
                                    <b-button v-if="type !== 'view'" size="sm" variant="link"
                                        class="delete-btn" :disabled="!form.sendReminder"
                                        @click="removeSchedule(daySchedule.day, time)">
                                        <i class="fa-solid fa-times"></i>
                                    </b-button>
                                </span>
                            </div>
                        </b-list-group-item>
                    </b-list-group>

                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                </div>
            </b-form-group>
        </validation-provider>
    </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'ReminderSchedule',
    components: {
        ValidationProvider,
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        form: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            selectedDays: [],
            selectedTime: '',
            schedules: [],
            daysOfWeek: [
                { text: this.$t('dateRangePickerLabels.daysOfWeek[1]'), value: '1' },
                { text: this.$t('dateRangePickerLabels.daysOfWeek[2]'), value: '2' },
                { text: this.$t('dateRangePickerLabels.daysOfWeek[3]'), value: '3' },
                { text: this.$t('dateRangePickerLabels.daysOfWeek[4]'), value: '4' },
                { text: this.$t('dateRangePickerLabels.daysOfWeek[5]'), value: '5' },
                { text: this.$t('dateRangePickerLabels.daysOfWeek[6]'), value: '6' },
                { text: this.$t('dateRangePickerLabels.daysOfWeek[0]'), value: '0' }
            ]
        }
    },
    created() {
        if (this.value) {
            this.parseSchedules(this.value);
        }
    },
    methods: {
        formatHourValue(hour) {
            return `${hour.toString().padStart(2, '0')}:00`;
        },
        formatHourDisplay(hour) {
            return `${hour.toString().padStart(2, '0')}:00`;
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        addSchedule() {
            if (this.selectedTime && this.selectedDays.length) {
                this.schedules.push({
                    days: [...this.selectedDays],
                    time: this.selectedTime
                });
                this.selectedDays = [];
                this.selectedTime = '';
            }
        },
        getDayTimes(daySchedule) {
            return daySchedule.split('at ')[1].split(', ');
        },
        getDayFromSchedule(daySchedule) {
            const dayName = daySchedule.split(' at ')[0];
            return this.daysOfWeek.find(d => this.getDayName(d.value) === dayName)?.value;
        },
        removeScheduleByDayAndTime(day, time) {
            this.schedules = this.schedules.filter(schedule => {
                if (schedule.time === time && schedule.days.includes(day)) {
                    // If schedule has multiple days, just remove this day
                    if (schedule.days.length > 1) {
                        schedule.days = schedule.days.filter(d => d !== day);
                        return true;
                    }
                    return false;
                }
                return true;
            });
        },
        formatSchedules() {
            const groupedByDay = {};

            this.schedules.forEach(schedule => {
                schedule.days.forEach(day => {
                    if (!groupedByDay[day]) {
                        groupedByDay[day] = {
                            day: day,
                            times: []
                        };
                    }
                    if (!groupedByDay[day].times.includes(schedule.time)) {
                        groupedByDay[day].times.push(schedule.time);
                    }
                });
            });

            // sort hours for each day
            Object.values(groupedByDay).forEach(dayGroup => {
                dayGroup.times.sort();
            });

            return Object.values(groupedByDay).map(dayGroup => ({
                day: dayGroup.day,
                times: dayGroup.times,
                text: `${this.getDayName(dayGroup.day)}: ${dayGroup.times.map(t => this.formatTime(t)).join(', ')}`
            }));
        },
        removeSchedule(day, time) {
            this.schedules = this.schedules.reduce((acc, schedule) => {
                if (schedule.time === time && schedule.days.includes(day)) {
                    // If schedule has multiple days, just remove this day
                    if (schedule.days.length > 1) {
                        schedule.days = schedule.days.filter(d => d !== day);
                        acc.push(schedule);
                    }
                    // If it's the only day, the schedule will be removed by not adding it to acc
                } else {
                    acc.push(schedule);
                }
                return acc;
            }, []);
        },
        getDayName(dayValue) {
            const day = this.daysOfWeek.find(d => d.value === dayValue);
            switch (day?.text) {
                case this.$t('dateRangePickerLabels.daysOfWeek[1]'): 
                    return this.$t('dateRangePickerLabels.daysOfWeekFull[1]');
                case this.$t('dateRangePickerLabels.daysOfWeek[2]'): 
                    return this.$t('dateRangePickerLabels.daysOfWeekFull[2]');
                case this.$t('dateRangePickerLabels.daysOfWeek[3]'): 
                    return this.$t('dateRangePickerLabels.daysOfWeekFull[3]');
                case this.$t('dateRangePickerLabels.daysOfWeek[4]'): 
                    return this.$t('dateRangePickerLabels.daysOfWeekFull[4]');
                case this.$t('dateRangePickerLabels.daysOfWeek[5]'): 
                    return this.$t('dateRangePickerLabels.daysOfWeekFull[5]');
                case this.$t('dateRangePickerLabels.daysOfWeek[6]'): 
                    return this.$t('dateRangePickerLabels.daysOfWeekFull[6]');
                case this.$t('dateRangePickerLabels.daysOfWeek[0]'): 
                    return this.$t('dateRangePickerLabels.daysOfWeekFull[0]');
                default: return day?.text;
            }
        },
        formatTime(time) {
            if (!time) return '';
            return time;
        },
        parseSchedules(value) {
            if (!value) return;

            try {
                this.schedules = value.split('|').map(schedule => {
                    const [days, time] = schedule.split(';');
                    // make sure parsed time is hour only
                    const hour = time.split(':')[0];
                    return {
                        days: days.split(','),
                        time: `${hour}:00`
                    };
                });
            } catch (e) {
                this.schedules = [];
            }
        }
    },
    watch: {
        schedules: {
            handler(newSchedules) {
                const formattedSchedules = newSchedules.map(s =>
                    `${s.days.join(',')};${s.time}`
                ).join('|');
                this.$emit('input', formattedSchedules);
            },
            deep: true
        },
        value: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.parseSchedules(newValue);
                }
            }
        }
    }
}
</script>

<style scoped>
.gap-2 {
    gap: 0.5rem;
}

.time-pills {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-left: 0.5rem;
}

.time-pill {
    display: inline-flex;
    align-items: center;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 1rem;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
}

.delete-btn {
    padding: 0;
    margin-left: 0.25rem;
    color: #dc3545;
    height: auto;
    min-height: 0;
}

.delete-btn:hover {
    color: #bd2130;
}

.day-label {
    min-width: 100px;
    font-weight: 500;
}
</style>