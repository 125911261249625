<template>
  <div class="animated fadeInRight" v-if="contextOrg">
    <div class="ibox">
      <div class="ibox-content p-5">
        <div class="row" v-if="hasRole('ADMIN')">
          <div class="col-12">
            <div class="btn-group float-right" v-if="false">
              <b-button href="#" class="btn btn-primary btn-sm mb-3" :to="{ name: 'organisationNew' }"><i class="fa fa-plus"></i> {{ $t('orgAdmin.organisation.actions.new') }}</b-button>
              <b-button href="#" class="btn btn-primary btn-sm ml-2 mb-3" :to="{ name: 'registerOrganisationRequestList' }"><i class="fa fa-users"></i> {{ $t('orgAdmin.registerOrganisationRequests.title') }}</b-button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xxl-8 col-xl-7 col-lg-12">
            <org-view-create-edit
                :organisation="organisation"
                :can-edit="hasRole('ADMIN')"
                initial-type="view"
                :success-callback="editOrgSuccessCallback" />
          </div>
          <div class="col-xxl-4 col-xl-5 col-lg-12">
            <h4 class="d-inline-block">{{ $t('orgAdmin.organisation.details.geolocation') }}</h4>
            <geo-location :markers="buildingCoordinates"></geo-location>
          </div>
          
          <org-summary v-if="organisationSummary" :summary="organisationSummary"></org-summary>
          
          <device-summary v-if="deviceSummary" :summary="deviceSummary"/>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import GeoLocation from '@/components/common/GeoLocationLeaflet'
import OrgViewCreateEdit from '@/components/views/admin/organisations/OrgViewCreateEdit'
import OrgSummary from '@/components/views/admin/organisations/OrgSummary'
import DeviceSummary from '@/components/views/devices/DeviceSummary';
import axios from 'axios';

export default {
  components: {
    OrgViewCreateEdit,
    GeoLocation,
    OrgSummary,
    DeviceSummary
  },
  data() {
    return {
      error: null,
      orgEdit: false,
      deviceSummary: null,
    }
  },
  created() {
    this.$store.dispatch('organisation/fetchOrganisationDetails', this.contextOrg.id)
    this.$store.dispatch('organisation/fetchOrganisationSummary', this.contextOrg.id)
  },
  watch: {
    contextOrg: function (newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        this.$store.dispatch('organisation/fetchOrganisationDetails', this.contextOrg.id)
        this.$store.dispatch('organisation/fetchOrganisationSummary', this.contextOrg.id)
      }
    }
  },
  mounted() {
    this.fetchDeviceSummary();
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      organisation: state => state.organisation.organisationDetails.entity,
      organisationSummary: state => state.organisation.organisationSummary,
      contextOrg: state => state.contextOrg
    }),
    buildingCoordinates() {
      if (this.organisation && this.organisation.buildings && this.organisation.buildings.length > 0) {
        let markers = []
        this.organisation.buildings.forEach(building => {
          if(building.coordinates && building.coordinates.latitude && building.coordinates.longitude) {
            let objForList = {}
            objForList.parentObject = building
            objForList.lat = building.coordinates.latitude
            objForList.lng = building.coordinates.longitude
            objForList.label = building.name
            objForList.selectCallback = function () {
              console.log(`selectCallback id ${building.id}`)
              this.$router.push({
                path: `/buildings/${building.id}`,
              })
            }.bind(this)

            markers.push(objForList)
          }
        })
        if(markers.length > 0){
          return markers
        }
      }
      return null
    }
  },
  methods: {
    editOrgSuccessCallback(){
      this.$store.dispatch("reloadContextOrgOptionsForAdmin")
      this.$store.dispatch('organisation/fetchOrganisationDetails', this.contextOrg.id)
    },
    async fetchDeviceSummary() {
      try {
        const endpoint = this.contextBuilding?.id 
          ? `/admin/buildings/${this.contextBuilding.id}/devices/summary`
          : `/admin/organisations/${this.contextOrg?.id}/devices/summary`;
        
        const response = await axios.get(endpoint);
        
        this.deviceSummary = response.data;
      } catch (error) {
        console.error('Error fetching device summary:', error);
        this.$bvToast.toast(this.$t('common.errors.summaryLoadError.description'), {
          title: this.$t('common.errors.summaryLoadError.title'),
          variant: 'danger',
          solid: true
        });
      }
    },
  }
}
</script>
