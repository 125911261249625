<template>
    <div class="device-summary charts-row">
        <div class="chart">
            <highcharts :options="assignmentOptions"></highcharts>
        </div>
        <div class="chart">
            <highcharts :options="activityOptions"></highcharts>
        </div>
        <div class="chart">
            <highcharts :options="signalOptions"></highcharts>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        summary: {
            type: Object,
            required: true
        },
    },
    computed: {
        assignmentOptions() {
            if (!this.summary) return null;
            return {
                credits: false,
                chart: {
                    type: 'pie',
                    height: '300px',
                },
                title: {
                    text: this.$t('systemAdmin.device.summary.deviceAssigned'),
                        style: {
                        fontSize: '14px',
                        fontWeight: 'bold'
                    }
                },
                subtitle: {
                    text: this.$t('systemAdmin.device.summary.total', { count: this.summary.total || 0 }),
                    align: 'center',
                    verticalAlign: 'middle',
                    y: 8,
                    style: {
                        fontSize: '16px',
                        fontWeight: 'bold'
                    }
                },
                colors: ['#61aeb7', '#ffc000', '#c3979f', '#e94f37'],
                plotOptions: {
                    pie: {
                        innerSize: '50%',
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}',
                            distance: -30
                        },
                        showInLegend: true
                    }
                },
                tooltip: {
                    headerFormat: '',
                    pointFormat: '{series.name}<br>{point.name}: <b>{point.y}</b>'
                },
                series: [{
                    name: this.$t('systemAdmin.device.summary.deviceAssigned'),
                    data: [
                        [this.$t('systemAdmin.device.assignment.assigned'), this.summary.assigned || 0],
                        [this.$t('systemAdmin.device.assignment.unassigned'), this.summary.unassigned || 0]
                    ]
                }]
            };
        },
        activityOptions() {
            if (!this.summary) return null;
            return {
                credits: false,
                chart: {
                    type: 'pie',
                    height: '300px',
                },
                title: {
                    text: this.$t('systemAdmin.device.summary.deviceActivity'),
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold'
                    }
                },
                subtitle: {
                    text: this.$t('systemAdmin.device.summary.total', { count: this.summary.total || 0 }),
                    align: 'center',
                    verticalAlign: 'middle',
                    y: 8,
                    style: {
                        fontSize: '16px',
                        fontWeight: 'bold'
                    }
                },
                colors: ['#61aeb7', '#ffc000', '#c3979f', '#e94f37'],
                plotOptions: {
                    pie: {
                        innerSize: '50%',
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}',
                            distance: -30
                        },
                        showInLegend: true
                    }
                },
                tooltip: {
                    headerFormat: '',
                    pointFormat: '{series.name}<br>{point.name}: <b>{point.y}</b>'
                },
                series: [{
                    name: this.$t('systemAdmin.device.summary.deviceActivity'),
                    data: [
                        [this.$t('systemAdmin.device.activity.active'), this.summary.active || 0],
                        [this.$t('systemAdmin.device.activity.inactive'), this.summary.inactive || 0],
                        [this.$t('systemAdmin.device.activity.neverSeen'), this.summary.neverSeen || 0]
                    ]
                }]
            };
        },
        signalOptions() {
            if (!this.summary) return null;
            return {
                credits: false,
                chart: {
                    type: 'pie',
                    height: '300px',
                },
                title: {
                    text: this.$t('systemAdmin.device.summary.deviceSignalStrength'),
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold'
                    }
                },
                subtitle: {
                    text: this.$t('systemAdmin.device.summary.total', { count: this.summary.total || 0 }),
                    align: 'center',
                    verticalAlign: 'middle',
                    y: 8,
                    style: {
                        fontSize: '16px',
                        fontWeight: 'bold'
                    }
                },
                colors: ['#61aeb7', '#ffc000', '#c3979f', '#025286', '#e94f37'],
                plotOptions: {
                    pie: {
                        innerSize: '50%',
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}',
                            distance: -30
                        },
                        showInLegend: true
                    }
                },
                tooltip: {
                    headerFormat: '',
                    pointFormat: '{series.name}<br>{point.name}: <b>{point.y}</b>'
                },
                series: [{
                    name: this.$t('systemAdmin.device.summary.deviceSignalStrength'),
                    data: [
                        [this.$t('systemAdmin.device.signalStrength.strong'), this.summary.signalStrong || 0],
                        [this.$t('systemAdmin.device.signalStrength.medium'), this.summary.signalMedium || 0],
                        [this.$t('systemAdmin.device.signalStrength.weak'), this.summary.signalWeak || 0],
                        [this.$t('systemAdmin.device.signalStrength.none'), this.summary.signalNone || 0],
                        [this.$t('systemAdmin.device.signalStrength.disconnected'), this.summary.signalDisconnected || 0],
                    ]
                }]
            };
        }
    }
};
</script>

<style scoped>
.charts-row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.chart {
    flex: 1 1 33%;
    box-sizing: border-box;
    padding: 10px;
    min-width: 300px;
}
</style>